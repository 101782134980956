<template>
  <div>
    <div class="row maindashboard-director">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <div class="col-12 no-padding">
          <div class="cardcontents">
            <div class="card-header">
              <!-- (금일) 진행중인 업무 -->
              {{$label('LBL0000940')}}
              <div class="card-more main-header-input">
                <c-plant
                  type="search"
                  name="plantCdTodayTask"
                  label=""
                  v-model="plantCdTodayTask"
                  @datachange="getWorkProgress" />
              </div>
            </div>
            <div class="card-body" style="height: 113px">
              <div class="row">
                <div v-for="(progress, idx) in workProgressList" 
                  :key="idx"
                  class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <q-btn dense :icon="iconItems[idx]" align="left" :color="colorItems[idx]" outline  unelevated :label="progress.workName" class="btn-fixed-width-height">
                    <q-badge color="red" floating transparent>
                      {{ progress.workCount }}
                    </q-badge>
                  </q-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 no-padding">
          <div class="cardcontents">
            <div class="card-header">
              금일 TBM 현황
              <div class="card-more main-header-input">
                <c-plant
                  type="search"
                  name="plantCdTbm"
                  label=""
                  v-model="plantCdTbm"
                  @datachange="getTbm"/>
              </div>
            </div>
            <div class="card-body">
              <c-table
                ref="gridTbm"
                :isTitle="false"
                :isDashboard="true"
                :columnSetting="false"
                :usePaging="false"
                :filtering="false"
                :isFullScreen="false"
                :hideBottom="true"
                gridHeight="305px"
                :columns="gridTbm.columns"
                :data="gridTbm.data"
                @linkClick="linkClickTbm"
              >
              </c-table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <!-- 작업허가 현황 [S]-->
        <div ref="main-dashborad" class="cardcontents directorMainLayer">
          <div class="card-header non-label-icon mapheader">
            작업허가서 현황
            <div class="card-more main-header-input main-header-input2 main-header-input2-director">
              <c-datepicker
                :range="true"
                :appendToBody="false"
                name="workPermitPeriod"
                v-model="mapObj.taskParam.workPermitPeriod"
                @datachange="getMapData"
              />
            </div>
          </div>
          <div class="card-body" ref="carousel" style="padding: 3px !important;">
            <q-carousel
              v-if="mapObj.maps && mapObj.maps.length > 0"
              class="workPermit-carousel"
              swipeable
              animated
              arrows
              infinite
              control-type="regular"
              control-color="grey-6"
              v-model="mapObj.slide"
              @input="changeCarousel"
            >
              <q-carousel-slide 
                v-for="(map, idx) in mapObj.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc">
                <template>
                  <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <div class="mapWorkIcons">
                    <q-expansion-item
                      dense
                      class="shadow-1 overflow-hidden"
                      style="border-radius: 10px"
                      icon="explore"
                      label="범례"
                      header-class="bg-primary text-white"
                      expand-icon-class="text-white"
                      expand-icon="expand_less"
                      expanded-icon="expand_more"
                      default-opened
                    >
                      <q-card>
                        <q-card-section style="padding:3px 10px; text-align: center;">
                          <ul>
                            <li v-for="(icon, iconIdx) in swpIconItems"
                              :key="iconIdx"
                            >
                              <q-icon 
                                :name="icon.attrVal2" 
                                class="text-red"
                                size="16px" />
                              {{ icon.codeName }}
                            </li>
                          </ul>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>
                  </div>
                  <template>
                    <!-- 직접 위치를 선택한 marked -->
                    <VueDraggableResizable
                      v-for="(item, idx) in map.maps"
                      :key="idx"
                      ref="markImage"
                      class="mainMarkImage"
                      :class="item.idx === mapObj.activeMarked ? 'selected' : ''"
                      :resizable="false"
                      :parent="true"
                      :draggable="false"
                      :x="item.locationXcoordinate * mapRate * mapObj.ratio"
                      :y="item.locationYcoordinate * mapRate * mapObj.ratio"
                      :w="40 " :h="40"
                      :grid="[20, 20]"
                      @activated="onMarkedInfo(item)"
                    >
                      <c-map-marker :isSelected="item.idx === mapObj.activeMarked" />
                      <q-menu 
                        :ref="'markedproxy' + item.idx" 
                        :content-class="'mainMapMenuLayer'"
                        anchor="top right"
                        self="top left">
                        <q-card 
                          v-for="(workPermit, idx) in mapObj.selectedWorkPermit.workPermits"
                          :key="idx"
                          flat bordered 
                          @click="openWorkPermitPop(workPermit)"
                          :class="['main-work-permit-card main-work-permit-card-cursor', setStepClass(workPermit.swpStepCd)]">
                          <q-item class="main-work-permit-card-section main-work-permit-card-item">
                            <q-item-section avatar>
                              <q-badge rounded :color="setStepColor(workPermit.swpStepCd)" :label="workPermit.swpStepName" />
                            </q-item-section>
                            <q-item-section>
                              <q-item-label>
                                <q-icon 
                                  v-for="(icon, iconIdx) in setTypeIcons(workPermit.sopWorkTypeCds)"
                                  :key="iconIdx"
                                  :name="icon" 
                                  class="text-red"
                                  size="25px" />
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                          <q-card-section class="main-work-permit-card-section">
                            <q-icon 
                              name="chevron_right" 
                              class="text-indigo" 
                            />
                            <b>{{workPermit.workSummary}}</b> ({{workPermit.workTime}})
                          </q-card-section>
                        </q-card>
                      </q-menu>
                    </VueDraggableResizable>
                  </template>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <!-- 등록된 지도가 없습니다. -->
            <el-empty v-else 
              :image-size="435"
              :description="$comm.getLangMessage('MSG0000122')"></el-empty>
          </div>
        </div>
        <!-- 작업허가 현황 [E]-->
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <div class="cardcontents">
          <div class="card-header">
            개선 진행 목록 (최근 10건)
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                name="plantCdImpr"
                label=""
                v-model="plantCdImpr"
                @datachange="getImprProgress"/>
            </div>
          </div>
          <div class="card-body nopadgrid">
            <c-table
              ref="gridImpr"
              tableId="gridImpr"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="310px"
              :columns="gridImpr.columns"
              :data="gridImpr.data"
              @linkClick="linkClickImpr"
            >
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <div class="cardcontents">
          <div class="card-header">
            <!-- 통합개선 이행율 현황 -->
            {{$label('LBL0000943')}}
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                label=""
                name="plantCdImprChart"
                v-model="plantCdImprChart"
                @datachange="getImprStatus" />
            </div>
            <div class="card-more main-header-input main-header-input2">
              <c-datepicker
                type="year"
                default="today"
                name="actionCompleteRequestYear"
                v-model="actionCompleteRequestYear"
                @input="getImprStatus"
              />
            </div>
          </div>
          <div class="card-body">
            <apexchart 
              ref="imprChart" 
              height="300px" 
              type="bar"
              :width="imprChart.chartWidth"
              :options="imprChart.chartOptions" 
              :series="imprChart.series"
              @dataPointSelection="selectedBarImpr">
            </apexchart>
          </div>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-notice-popup></c-notice-popup>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
import VueDraggableResizable from 'vue-draggable-resizable'

export default {
  name: 'main-dashboard-director',
  components: { 
    VueDraggableResizable,
    apexchart: VueApexCharts
  },
  props: {
  },
  data() {
    return {
      mapObj: {
        activeMarked: 0,
        selectedWorkPermit: {
          workPermits: [],
        },
        envUrl: '',
        url: '',
        taskParam: {
          workPermitPeriod: [],
        },
        maps: [],
        slide: 0,
        height: 575,
        ratio: 1,
        orignRatio: 1,
        endIdx: 1,
      },
      plantCdTodayTask: null,
      plantCd2: null,
      plantCdImpr: null,
      plantCdImprChart: null,
      plantCdTbm: null,
      imprChart: {
        chartOptions: {
          annotations: { 
            xaxis: [],
          },
          chart: {
            type: 'bar',
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            show: true,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          colors: [
            '#FFC107', '#00BCD4', '#F44336',
          ],
          fill: {
            opacity: 1,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 10
          },
        },
        series: [
          {
            id: 'actioning',
            name: '조치중',
            data: [],
          },
          {
            id: 'complete',
            name: '개선완료(총합)',
            data: [],
          },
          {
            id: 'overAction',
            name: '지연 건수',
            data: [],
          },
        ],
        chartWidth: '100%',
      },
      gridImpr: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: 'LBLWORK',
            align: 'center',
            style: 'width:120px',
            ellipsis: true,
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            type: "link",
            ellipsis: true,
            sortable: false,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
      },
      gridTbm: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:10%',
            sortable: false,
          },
          // {
          //   name: 'tbmNo',
          //   field: 'tbmNo',
          //   label: 'TBM번호',
          //   align: 'center',
          //   style: 'width:15%',
          //   sortable: false,
          // },
          {
            name: 'tbmName',
            field: 'tbmName',
            label: 'TBM명',
            style: 'width:40%',
            align: 'left',
            sortable: false,
            type: "link",
          },
          {
            name: 'tbmDt',
            field: 'tbmDt',
            label: 'TBM일시',
            align: 'center',
            style: 'width:15%',
            sortable: false,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '업체',
            align: 'center',
            style: 'width:15%',
            sortable: false,
          },
          {
            name: 'count',
            field: 'count',
            label: '인원수',
            align: 'center',
            child: [
              {
                name: 'attendeeCount',
                field: 'attendeeCount',
                label: '참가자',
                align: 'center',
                style: 'width:10%',
                sortable: false,
              },
              {
                name: 'signCount',
                field: 'signCount',
                label: '서명자',
                align: 'center',
                style: 'width:10%',
                sortable: false,
              },
            ]
          },
        ],
        data: [],
      },
      swpIconItems: [],
      rateUrl: '',
      workProgressUrl: '',
      imprProgressUrl: '',
      tbmUrl: '',
      workProgressList: [],
      iconItems: ['school','settings_applications','build','build_circle','restore','manage_accounts','report','report_problem'],
      colorItems: ['primary','secondary','amber-9','deep-orange','secondary','brown-5','purple','green-7',],
      ibmTaskTypeCdList: [],
      actionCompleteRequestYear: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  activated() {
    this.getMapData();
  },
  deactivated() {
    window.removeEventListener('resize', this.setSize);
  },
  beforeDestroy() {
  },
  computed: {
    mapRate() {
      return 0.870
    },
  },
  methods: {
    init() {
      // url setting
      this.rateUrl = selectConfig.main.imprChart.url;
      this.tbmUrl = selectConfig.main.tbm.url;
      this.imprProgressUrl = selectConfig.main.imprProgress.url;
      this.workProgressUrl = selectConfig.main.workProgress.url;
      this.mapObj.url = selectConfig.main.map.url
      this.mapObj.envUrl = selectConfig.main.env.url
      this.$comm.getComboItems('SOP_WORK_TYPE_CD').then(_result => {
        this.swpIconItems = _result;
      });
      // list setting
      this.getImprStatus();
      this.getImprProgress();
      this.getWorkProgress();
      this.getTbm();
      this.getMapPriodParam();
    },
    setSize() {
      setTimeout(() => {
        this.getMapData();
      }, 300);
    },
    getMapPriodParam() {
      /**
       * 초기 지도 정보를 불러올 때에는 기간 정보가 넘어가지 않게 처리
       */
      this.$http.url = this.mapObj.envUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$set(this.mapObj.taskParam, 'workPermitPeriod', [_result.data[0].start, _result.data[0].end])
      },);
    },
    getMapData() {
      let defaultPlantCd = window.sessionStorage.getItem('defaultPlantCd');
      this.$http.url = this.mapObj.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: defaultPlantCd,
        workPermitPeriod: this.mapObj.taskParam.workPermitPeriod,
      }
      this.$http.request((_result) => {
        let maps = this.$_.filter(_result.data, item => {
          return item.mapSrc
        })
        if (maps.length > 0) {
          /**
           * 지도는 1개
           */
          this.$_.forEach(maps, item => {
            this.mapObj.endIdx = 1;
            if (item.maps && item.maps.length > 0) {
              this.$_.forEach(item.maps, map => {
                this.$set(map, 'idx', this.mapObj.endIdx)
                this.mapObj.endIdx++;
              })
            }
          })

          this.mapObj.maps = this.$_.cloneDeep(maps)
          this.setMapSize(0)
        } else {
          this.mapObj.maps = [];
        }
      },);
    },
    setMapSize(index) {
      if (this.mapObj.maps && this.mapObj.maps.length > 0) {
        this.mapObj.orignRatio = this.mapObj.maps[index].ratio;
        
        this.$refs['carousel'].style.setProperty("--map-ratio", this.mapObj.maps[index].ratio);
        
        this.mapObj.height = 575
        let resizeRatio = Math.round((this.mapObj.height / 575) * 100) / 100;
        let _height = this.mapObj.height * this.mapRate
        this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
        this.mapObj.ratio = resizeRatio
      }
    },
    onMarkedInfo(markedInfo) {
      this.$set(this.mapObj.selectedWorkPermit, 'workPermits', markedInfo.resultTypes);
      this.$set(this.mapObj, 'activeMarked', markedInfo.idx)
    },
    changeCarousel() {
      this.setMapSize(this.mapObj.slide)
    },
    /* eslint-disable no-unused-vars */
    setStepColor(swpStepCd) {
      let color = '';
      switch(swpStepCd) {
        case 'SS00000005':
          color = 'light-blue';
          break;
        case 'SS00000010':
          color = 'amber';
          break;
        case 'SS00000020':
          color = 'light-green';
          break;
      }
      return color;
    },
    setStepClass(swpStepCd) {
      let _class = '';
      switch(swpStepCd) {
        case 'SS00000005':
        _class = 'main-work-permit-card-light-blue';
          break;
        case 'SS00000010':
        _class = 'main-work-permit-card-amber';
          break;
        case 'SS00000020':
        _class = 'main-work-permit-card-light-green';
          break;
      }
      return _class;
    },
    setTypeIcons(sopWorkTypeCds) {
      let icons = [];
      let typeArray = sopWorkTypeCds && sopWorkTypeCds.length > 0 ? sopWorkTypeCds.split(',') : [];
      if (typeArray && typeArray.length > 0) {
        this.$_.forEach(typeArray, type => {
          switch(type) {
            case 'SPT0000001': // 일반
              icons.push('engineering');
              break;
            case 'SPT0000005': // 화기
              icons.push('local_fire_department');
              break;
            case 'SSWT000001': // 밀폐공간
              icons.push('dangerous');
              break;
            case 'SSWT000005': // 정전
              icons.push('flash_off');
              break;
            case 'SSWT000010': // 방사선
              icons.push('warning');
              break;
            case 'SSWT000015': // 굴착
              icons.push('hardware');
              break;
            case 'SSWT000020': // 고소
              icons.push('stairs');
              break;
            case 'SSWT000025': // 중장비
              icons.push('agriculture');
              break;
          }
        })
      }
      return icons;
    },
    openWorkPermitPop(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = 'LBL0000938' //'작업허가서 상세';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.permitTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePermitPopup;
    },
    closePermitPopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    getWorkProgress() {
      this.$http.url = this.workProgressUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdTodayTask
      }
      this.$http.request((_result) => {
        this.workProgressList = _result.data
      },);
    },
    getImprProgress() {
      this.$http.url = this.imprProgressUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdImpr,
      };
      this.$http.request((_result) => {
        this.gridImpr.data = _result.data
      },);
    },
    getTbm() {
      this.$http.url = this.tbmUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdTbm,
      };
      this.$http.request((_result) => {
        this.gridTbm.data = _result.data
      },);
    },
    getImprStatus() {
      let dates = [];
      if (this.actionCompleteRequestYear) {
        dates = [this.actionCompleteRequestYear + '-01-01', this.actionCompleteRequestYear + '-12-31'];
      }
      this.$http.url = this.rateUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdImprChart,
        actionCompleteRequestDates: dates,
      }
      let rate = [];
      this.$http.request((_result) => {
        let taskIdx = 0;
        this.$_.forEach(_result.data, task => {
          this.$_.forEachRight(this.imprChart.series, item => {
            if (!rate[taskIdx] && task[item.id] > 0) {
              rate[taskIdx] = task.requestTotal > 0 ?
                String(this.$_.round((task.complete / task.requestTotal) * 100)) + '%' : '0%';
            }
          })
          this.ibmTaskTypeCdList.push({
            code: task.ibmTaskTypeCd,
            codeName: task.ibmTaskTypeName,
          });
          taskIdx++;
        })
        this.$_.forEach(this.imprChart.series, item => {
          item.data = this.$_.map(_result.data, item.id)
        })
        let xaxis = this.$_.map(_result.data, 'ibmTaskTypeName');
        let annotations = [];
        rate.forEach((r, idx) => {
          // xaxis[idx] += (r ? '(' + r + ')' : '');
          annotations[idx] = {
            x: xaxis[idx],
            strokeDashArray: 0,
            borderColor: '#EEEEEE',
            borderWidth: 0,
            label: {
              borderColor: '#775DD0',
              orientation: 'horizontal',
              style: {
                color: '#fff',
                fontSize: '12px',
                background: '#775DD0',
              },
              text: r ? r : '',
              // text: r ? '이행률 : ' + r : '',
            }
          }
        })
        xaxis.forEach((x, idx) => {
          if (!annotations[idx]) {
            annotations[idx] = {
              x: x,
              strokeDashArray: 0,
              borderColor: '#EEEEEE',
              borderWidth: 0,
              // borderColor: '#775DD0',
              label: {
                borderColor: '#775DD0',
                orientation: 'horizontal',
                style: {
                  color: '#fff',
                  fontSize: '12px',
                  background: '#775DD0',
                },
                text: '',
              }
            }
          }
        })
        this.imprChart.chartOptions.xaxis.categories = xaxis;
        this.imprChart.chartOptions.annotations.xaxis = annotations;
        
        this.imprChart.chartWidth = '100%';
        this.$refs['imprChart'].refresh();
      },);
    },
    linkClickImpr(row) {
      this.popupOptions.title = 'LBL0000947'; // 개선 상세
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
        ibmTaskTypeCd: row.ibmTaskTypeCd,
      };
      this.popupOptions.width = "80%"
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    linkClickTbm(row) {
      this.popupOptions.title = 'TBM 상세';
      this.popupOptions.param = {
        tbmId: row.tbmId
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/tbm/tbmDetail.vue'}`);
      this.popupOptions.width = "80%"
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    selectedBarImpr(e, chart, opts) {
      let stepGubun = '';
      switch (opts.w.config.series[opts.seriesIndex].name) {
        case '조치중':
          stepGubun = 'actioning'
          break;
        case '개선완료(총합)':
          stepGubun = 'complete'
          break;
        case '지연 건수':
          stepGubun = 'overAction'
          break;
        default:
          break;
      }
      this.popupOptions.width = '90%';
      this.popupOptions.title = "LBL0000948"; // 개선목록 (업무별 현황)
      this.popupOptions.param = {
        plantCd: this.plantCdImprChart,
        ibmTaskTypeCd: this.$_.filter(this.ibmTaskTypeCdList, { codeName: this.imprChart.chartOptions.xaxis.categories[opts.dataPointIndex] })[0].code,
        actionCompleteRequestDates: [this.actionCompleteRequestYear + '-01-01', this.actionCompleteRequestYear + '-12-31'],
        stepGubun: stepGubun,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/impr.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
  }
};
</script>
<style lang="sass">
.main-work-permit-card-cursor
  cursor: pointer
.maindashboard-director 
  .fullscreen-btn
    .q-btn__wrapper
      min-width: 10px !important
      min-height: 10px !important
  .no-padding
    padding: 0px 0px 6px 0px !important
  .cardcontents
    box-shadow: 0 !important
    border: 1px solid rgba(0,0,0,0.12) !important
    border-radius: 10px !important
    margin-top: 6px
    margin-bottom: 6px
    margin-right: 6px
    .card-body
      padding: 10px !important
      border-radius: 0 0 10px 10px !important
      background: #fff
    .card-header:before
      font-family: "Material Icons"
      content: "\e876"
    .card-header
      border-bottom-width: 0px !important
      border-radius: 10px 10px 0 0 !important
      height: 40px
      color: #0072c6
      background: #f8f8f9
      display: inline-block
      padding-top: 8px
      width: 100%
      .card-more
        float: right !important
        cursor: pointer
        color: #d2d2d2
      .card-more:hover
        color: #1ab394
  .main-header-input
    margin-top: -5px
    padding-right: 5px
  .main-header-input2
    margin-top: -5px
    padding-right: 5px
    width: 240px !important
    .customDate
      padding-bottom: 0 !important
      .q-field__inner
        padding: 0 !important
  .cardcontents .card-body.nopadgrid
    padding-top: 0px !important
  .btn-fixed-width-height
    width: 95%
    margin: 3px !important
    pointer-events: none

  .directorMainLayer
    .workPermit-carousel
      border-radius: 10px
      height: var(--map-height) !important
      .q-carousel__slide
        width: calc(var(--map-ratio) * var(--map-height)) !important
        max-width: calc(var(--map-ratio) * var(--map-height)) !important
        position: relative
      .q-carousel--padding, .q-carousel__slide
        padding: 0 !important
      .q-carousel__arrow
        transition: all 0.5s
        opacity: 0.2
      .q-carousel__arrow:hover
        transition: all 0.5s
        opacity: 0.8
    .caption-work2
      font-size: 0.9em
      white-space: normal
      color: #fff
      padding: 10px
    .caption-work
      font-size: 0.9em
      // font-style: italic
      white-space: normal
      color: #555
      margin-top: 4px
    .mainmapstart
      float: right
      margin-right: 0px
      margin-top: -5px
    .mainMarkImage
      padding: 0px !important
      font-size: 20px
      height: 30px !important
      position: absolute
      padding: 16px
      color: #C10015
      z-index: 3 !important
      .markImage-abbreviation-parent
        text-align: start
        position: absolute
        .markImage-abbreviation
          cursor: pointer
          display: inline-block
          width: auto
          border-left: 1px solid #757575
          border-right: 1px solid #757575
          border-top: 1px solid #757575
          border-bottom: 1px solid #757575
          border-radius: 5px
          .markImage-tran
            border-radius: 5px
          .markImage-abbreviation-banner
            border-right: 1px solid #757575
            display: inline-block
            text-align: center
            min-width:60px
            padding: 1px !important
            border-radius: 5px 0px 0px 5px
            i
              font-size: 20px
            div
              font-size: 12px !important
          .markImage-abbreviation-banner.last-banner
            border-right: 0
            border-radius: 0px 5px 5px 0px
          .markImage-abbreviation-plus
            display: inline-block
            text-align: center
            min-width:40px
          .markImage-abbreviation-banner-detail
            border-radius: 5px
            .q-banner__avatar i
              font-size: 20px !important
            .q-banner__content
              font-size: 12px !important
    .markImage-abbreviation > div
      -webkit-animation: fadeInFromNone 0.1s ease-out
      -moz-animation: fadeInFromNone 0.1s ease-out
      -o-animation: fadeInFromNone 0.1s ease-out
      animation: fadeInFromNone 0.1s ease-out
</style>
<style scoped>
.main-work-permit-card-light-blue {
  border-top: 2px solid #03a9f4;
}
.main-work-permit-card-amber {
  border-top: 2px solid #ffc107;
}
.main-work-permit-card-light-green {
  border-top: 2px solid #8bc34a;
}
.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: rgba(0, 143, 251, 0.85) !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 9999;
  font-size: 12px;
}
/* rgba(220, 221, 221, 0.5) !important; */
.mapWorkIcons {
  position: absolute;
  /* background-color: lightgrey;   */
  /* border-radius: 10px; */
  right: 0px;
  bottom: 0px;
  padding: 5px 10px;
  font-weight: 700;
  line-height: 20px;
  z-index: 9999;
  font-size: 11px;
}
.mapWorkIcons .q-item {
  padding: 0px 10px;
}
.mapWorkIcons ul {
  list-style-type: none;
  margin-bottom: 0px !important;
}
</style>