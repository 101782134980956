var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row maindashboard-director" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c("div", { staticClass: "col-12 no-padding" }, [
              _c("div", { staticClass: "cardcontents" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v(" " + _vm._s(_vm.$label("LBL0000940")) + " "),
                  _c(
                    "div",
                    { staticClass: "card-more main-header-input" },
                    [
                      _c("c-plant", {
                        attrs: {
                          type: "search",
                          name: "plantCdTodayTask",
                          label: "",
                        },
                        on: { datachange: _vm.getWorkProgress },
                        model: {
                          value: _vm.plantCdTodayTask,
                          callback: function ($$v) {
                            _vm.plantCdTodayTask = $$v
                          },
                          expression: "plantCdTodayTask",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "card-body",
                    staticStyle: { height: "113px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.workProgressList, function (progress, idx) {
                        return _c(
                          "div",
                          {
                            key: idx,
                            staticClass:
                              "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                          },
                          [
                            _c(
                              "q-btn",
                              {
                                staticClass: "btn-fixed-width-height",
                                attrs: {
                                  dense: "",
                                  icon: _vm.iconItems[idx],
                                  align: "left",
                                  color: _vm.colorItems[idx],
                                  outline: "",
                                  unelevated: "",
                                  label: progress.workName,
                                },
                              },
                              [
                                _c(
                                  "q-badge",
                                  {
                                    attrs: {
                                      color: "red",
                                      floating: "",
                                      transparent: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(progress.workCount) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-12 no-padding" }, [
              _c("div", { staticClass: "cardcontents" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v(" 금일 TBM 현황 "),
                  _c(
                    "div",
                    { staticClass: "card-more main-header-input" },
                    [
                      _c("c-plant", {
                        attrs: {
                          type: "search",
                          name: "plantCdTbm",
                          label: "",
                        },
                        on: { datachange: _vm.getTbm },
                        model: {
                          value: _vm.plantCdTbm,
                          callback: function ($$v) {
                            _vm.plantCdTbm = $$v
                          },
                          expression: "plantCdTbm",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("c-table", {
                      ref: "gridTbm",
                      attrs: {
                        isTitle: false,
                        isDashboard: true,
                        columnSetting: false,
                        usePaging: false,
                        filtering: false,
                        isFullScreen: false,
                        hideBottom: true,
                        gridHeight: "305px",
                        columns: _vm.gridTbm.columns,
                        data: _vm.gridTbm.data,
                      },
                      on: { linkClick: _vm.linkClickTbm },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c(
              "div",
              {
                ref: "main-dashborad",
                staticClass: "cardcontents directorMainLayer",
              },
              [
                _c(
                  "div",
                  { staticClass: "card-header non-label-icon mapheader" },
                  [
                    _vm._v(" 작업허가서 현황 "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-more main-header-input main-header-input2 main-header-input2-director",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            range: true,
                            appendToBody: false,
                            name: "workPermitPeriod",
                          },
                          on: { datachange: _vm.getMapData },
                          model: {
                            value: _vm.mapObj.taskParam.workPermitPeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.mapObj.taskParam,
                                "workPermitPeriod",
                                $$v
                              )
                            },
                            expression: "mapObj.taskParam.workPermitPeriod",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    ref: "carousel",
                    staticClass: "card-body",
                    staticStyle: { padding: "3px !important" },
                  },
                  [
                    _vm.mapObj.maps && _vm.mapObj.maps.length > 0
                      ? _c(
                          "q-carousel",
                          {
                            staticClass: "workPermit-carousel",
                            attrs: {
                              swipeable: "",
                              animated: "",
                              arrows: "",
                              infinite: "",
                              "control-type": "regular",
                              "control-color": "grey-6",
                            },
                            on: { input: _vm.changeCarousel },
                            model: {
                              value: _vm.mapObj.slide,
                              callback: function ($$v) {
                                _vm.$set(_vm.mapObj, "slide", $$v)
                              },
                              expression: "mapObj.slide",
                            },
                          },
                          _vm._l(_vm.mapObj.maps, function (map, idx) {
                            return _c(
                              "q-carousel-slide",
                              {
                                key: idx,
                                attrs: { name: idx, "img-src": map.mapSrc },
                              },
                              [
                                [
                                  _c("div", {
                                    staticClass: "mapTitleDiv",
                                    domProps: {
                                      textContent: _vm._s(map.mapName),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "mapWorkIcons" },
                                    [
                                      _c(
                                        "q-expansion-item",
                                        {
                                          staticClass:
                                            "shadow-1 overflow-hidden",
                                          staticStyle: {
                                            "border-radius": "10px",
                                          },
                                          attrs: {
                                            dense: "",
                                            icon: "explore",
                                            label: "범례",
                                            "header-class":
                                              "bg-primary text-white",
                                            "expand-icon-class": "text-white",
                                            "expand-icon": "expand_less",
                                            "expanded-icon": "expand_more",
                                            "default-opened": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "q-card",
                                            [
                                              _c(
                                                "q-card-section",
                                                {
                                                  staticStyle: {
                                                    padding: "3px 10px",
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "ul",
                                                    _vm._l(
                                                      _vm.swpIconItems,
                                                      function (icon, iconIdx) {
                                                        return _c(
                                                          "li",
                                                          { key: iconIdx },
                                                          [
                                                            _c("q-icon", {
                                                              staticClass:
                                                                "text-red",
                                                              attrs: {
                                                                name: icon.attrVal2,
                                                                size: "16px",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  icon.codeName
                                                                ) +
                                                                " "
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._l(map.maps, function (item, idx) {
                                    return _c(
                                      "VueDraggableResizable",
                                      {
                                        key: idx,
                                        ref: "markImage",
                                        refInFor: true,
                                        staticClass: "mainMarkImage",
                                        class:
                                          item.idx === _vm.mapObj.activeMarked
                                            ? "selected"
                                            : "",
                                        attrs: {
                                          resizable: false,
                                          parent: true,
                                          draggable: false,
                                          x:
                                            item.locationXcoordinate *
                                            _vm.mapRate *
                                            _vm.mapObj.ratio,
                                          y:
                                            item.locationYcoordinate *
                                            _vm.mapRate *
                                            _vm.mapObj.ratio,
                                          w: 40,
                                          h: 40,
                                          grid: [20, 20],
                                        },
                                        on: {
                                          activated: function ($event) {
                                            return _vm.onMarkedInfo(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("c-map-marker", {
                                          attrs: {
                                            isSelected:
                                              item.idx ===
                                              _vm.mapObj.activeMarked,
                                          },
                                        }),
                                        _c(
                                          "q-menu",
                                          {
                                            ref: "markedproxy" + item.idx,
                                            refInFor: true,
                                            attrs: {
                                              "content-class":
                                                "mainMapMenuLayer",
                                              anchor: "top right",
                                              self: "top left",
                                            },
                                          },
                                          _vm._l(
                                            _vm.mapObj.selectedWorkPermit
                                              .workPermits,
                                            function (workPermit, idx) {
                                              return _c(
                                                "q-card",
                                                {
                                                  key: idx,
                                                  class: [
                                                    "main-work-permit-card main-work-permit-card-cursor",
                                                    _vm.setStepClass(
                                                      workPermit.swpStepCd
                                                    ),
                                                  ],
                                                  attrs: {
                                                    flat: "",
                                                    bordered: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openWorkPermitPop(
                                                        workPermit
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "q-item",
                                                    {
                                                      staticClass:
                                                        "main-work-permit-card-section main-work-permit-card-item",
                                                    },
                                                    [
                                                      _c(
                                                        "q-item-section",
                                                        {
                                                          attrs: { avatar: "" },
                                                        },
                                                        [
                                                          _c("q-badge", {
                                                            attrs: {
                                                              rounded: "",
                                                              color:
                                                                _vm.setStepColor(
                                                                  workPermit.swpStepCd
                                                                ),
                                                              label:
                                                                workPermit.swpStepName,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "q-item-section",
                                                        [
                                                          _c(
                                                            "q-item-label",
                                                            _vm._l(
                                                              _vm.setTypeIcons(
                                                                workPermit.sopWorkTypeCds
                                                              ),
                                                              function (
                                                                icon,
                                                                iconIdx
                                                              ) {
                                                                return _c(
                                                                  "q-icon",
                                                                  {
                                                                    key: iconIdx,
                                                                    staticClass:
                                                                      "text-red",
                                                                    attrs: {
                                                                      name: icon,
                                                                      size: "25px",
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "q-card-section",
                                                    {
                                                      staticClass:
                                                        "main-work-permit-card-section",
                                                    },
                                                    [
                                                      _c("q-icon", {
                                                        staticClass:
                                                          "text-indigo",
                                                        attrs: {
                                                          name: "chevron_right",
                                                        },
                                                      }),
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            workPermit.workSummary
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " (" +
                                                          _vm._s(
                                                            workPermit.workTime
                                                          ) +
                                                          ") "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                              ],
                              2
                            )
                          }),
                          1
                        )
                      : _c("el-empty", {
                          attrs: {
                            "image-size": 435,
                            description: _vm.$comm.getLangMessage("MSG0000122"),
                          },
                        }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" 개선 진행 목록 (최근 10건) "),
                _c(
                  "div",
                  { staticClass: "card-more main-header-input" },
                  [
                    _c("c-plant", {
                      attrs: { type: "search", name: "plantCdImpr", label: "" },
                      on: { datachange: _vm.getImprProgress },
                      model: {
                        value: _vm.plantCdImpr,
                        callback: function ($$v) {
                          _vm.plantCdImpr = $$v
                        },
                        expression: "plantCdImpr",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body nopadgrid" },
                [
                  _c("c-table", {
                    ref: "gridImpr",
                    attrs: {
                      tableId: "gridImpr",
                      isTitle: false,
                      isDashboard: true,
                      columnSetting: false,
                      usePaging: false,
                      filtering: false,
                      isFullScreen: false,
                      hideBottom: true,
                      gridHeight: "310px",
                      columns: _vm.gridImpr.columns,
                      data: _vm.gridImpr.data,
                    },
                    on: { linkClick: _vm.linkClickImpr },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" " + _vm._s(_vm.$label("LBL0000943")) + " "),
                _c(
                  "div",
                  { staticClass: "card-more main-header-input" },
                  [
                    _c("c-plant", {
                      attrs: {
                        type: "search",
                        label: "",
                        name: "plantCdImprChart",
                      },
                      on: { datachange: _vm.getImprStatus },
                      model: {
                        value: _vm.plantCdImprChart,
                        callback: function ($$v) {
                          _vm.plantCdImprChart = $$v
                        },
                        expression: "plantCdImprChart",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "card-more main-header-input main-header-input2",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        type: "year",
                        default: "today",
                        name: "actionCompleteRequestYear",
                      },
                      on: { input: _vm.getImprStatus },
                      model: {
                        value: _vm.actionCompleteRequestYear,
                        callback: function ($$v) {
                          _vm.actionCompleteRequestYear = $$v
                        },
                        expression: "actionCompleteRequestYear",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("apexchart", {
                    ref: "imprChart",
                    attrs: {
                      height: "300px",
                      type: "bar",
                      width: _vm.imprChart.chartWidth,
                      options: _vm.imprChart.chartOptions,
                      series: _vm.imprChart.series,
                    },
                    on: { dataPointSelection: _vm.selectedBarImpr },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("c-notice-popup"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }